import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "redux/error";

import { ErrorModal } from "./ErrorModal";

export const ErrorModalWrapper: React.FC = () => {
  const dispatch = useDispatch();

  const errorMessage = useSelector(selectors.getError);

  React.useEffect(() => {
    setTimeout(() => {
      dispatch(actions.clearError());
    }, 3000);
  }, [dispatch, errorMessage]);

  return <ErrorModal errorMessage={errorMessage} />;
};
