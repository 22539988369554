import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ModalState } from "types";
import { RootState } from "../store";

// Selectors
export const selectors = {
  getModalState: (state: RootState) => state.modal.modalState || {},
  getOpenModalName: (state: RootState) => state.modal.openModalName,
};

// Slice
export const initialState: ModalState = {
  modalState: {},
  openModalName: "",
};

export const { actions, reducer } = createSlice({
  name: "modal",
  initialState,
  reducers: {
    closeModal: (state: ModalState) => {
      return {
        ...state,
        modalState: undefined,
        openModalName: "",
      };
    },
    openModal: (state: ModalState, action: PayloadAction<ModalState>) => {
      return {
        ...state,
        modalState: action.payload.modalState,
        openModalName: action.payload.openModalName,
      };
    },
    updateModalState: (
      state: ModalState,
      action: PayloadAction<Record<string, any>>
    ) => {
      return {
        ...state,
        modalState: {
          ...state.modalState,
          ...action.payload,
        },
      };
    },
  },
});
