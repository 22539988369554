import React from "react";
import Modal from "react-bootstrap/Modal";

interface Props {
  errorMessage: string;
}

export const ErrorModal: React.FC<Props> = ({ errorMessage }) => {
  return (
    <Modal size="sm" show={Boolean(errorMessage)}>
      <Modal.Body>{errorMessage}</Modal.Body>
    </Modal>
  );
};
