import React from "react";
import Container from "react-bootstrap/Container";

import { ErrorModalWrapper as ErrorModal } from "modals/ErrorModal";
import { Navbar } from "../Navbar";

type LayoutProps = {
  children: React.ReactNode;
};

export const Layout = ({ children }: LayoutProps): JSX.Element => {
  return (
    <>
      <Navbar />

      <Container className="pt-5">{children}</Container>

      <ErrorModal />
    </>
  );
};
